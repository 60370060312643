// header

.header {
	height: $headerHeight--desktop;
	z-index: 1060;
	+ .mainContentWrapper {
		height: calc(100vh - 70px);
	}
	@include XXXL {
		height: $headerHeight--laptop;
	}
	> div {
		height: inherit;
		background-color: var(--color--primary--background);
		position: relative;
		&.transparent {
			background-color: var(--color--primary--background-70);
		}
	}
	@include S {
		margin: 0;
	}
	.returnArrow__wrapper {
		border-right: 1px solid var(--color--primary--foreground-10);
		left: -2px;
		margin-right: 30px;
		&::after {
			color: var(--color--primary--foreground-10);
		}
		&:hover::after {
			bottom: -25px;
			color: var(--color--primary--foreground);
		}
		@include minDesktop {
			margin-right: 20px;
			left: -17px;
		}
	}
	.mobileMask {
		position: fixed;
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		z-index: 100;
		opacity: 0;
		background-color: color-mod(var(--color--primary--background-40) shade(0.9));
		transition: opacity 0.2s ease-in;
	}
	&.expanded {
		.mobileMask {
			opacity: 1;
			width: 100vw;
			height: 100vh;
		}
	}
	.containerHeader {
		position: relative;
		height: inherit;
		padding: 0;
		align-items: center;
		.containerHeader__menu {
			flex: 1;
			z-index: 900;
			height: inherit;
			nav {
				width: 100%;
				position: relative;
				height: inherit;
				&:not(:last-child) {
					padding-right: 20px;
				}
			}
		}
		&__images {
			.imageItem {
				img {
					height: 45px;
					width: auto;
				}
				&__mobile {
					display: none;
					img {
						height: 35px;
					}
				}
			}
		}
		&__logoWrapper {
			img {
				width: auto;
				height: 38px;
			}
			.logo {
				&__mobile {
					height: 26px;
					display: none;
				}
			}
		}
		.more {
			list-style: none;
			position: relative;
			z-index: 200;
			cursor: pointer;
			order: 1;
			color: rgba($menuFontColor, 0.4);
			font-size: 12px;
			letter-spacing: 1.5px;
			.moreTitle {
				color: var(--color--primary--foreground);
			}
			img {
				width: auto;
				height: 16px;
			}
			&__text {
				span {
					border-radius: 5px;
					padding: 10px;
				}
			}
			&::after {
				content: '';
				height: 34%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				width: 1px;
				border-left: 1px solid rgba(255, 255, 255, 0.1);
				z-index: -1;
			}
			.desktopMenuPosition {
				z-index: 1;
			}
			&.iconWrapper {
				padding-left: 15px;
				.desktopMenuPosition {
					width: 40px;
					height: 40px;
					border-radius: 50%;
				}
				&:hover .desktopMenuPosition {
					background-color: var(--color--interactive--background);
				}
			}
			@include hooverSupported {
				&:hover {
					.moreTitle {
						background-color: var(--color--interactive--background);
					}
				}
			}
		}
		.mobileMenuWrapper {
			display: none;
			position: fixed;
			right: 0;
			top: 0;
			&.expanded {
				display: block;
			}
			@include hooverSupported {
				&:hover {
					display: block;
				}
			}
		}
		> a {
			padding: 15px;
			font-size: 13px;
		}
	}
}
