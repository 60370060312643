// header - mobile styles

.header {
	@include touchDevices {
		height: $headerHeight--mobile;
		.returnArrow {
			height: 25px;

			&__wrapper {
				left: -25px;
				margin-right: 10px;
			}
		}
		.containerHeader {
			&__images {
				.imageItem {
					&__desktop {
						display: none;
					}

					&__mobile {
						display: block;
					}
				}
			}

			.desktopMenu a.logo {
				width: 110px;
				height: 110px;
			}

			&__logoWrapper {
				width: 500px;

				a {
					flex-grow: 0;
				}

				.logo {
					&__desktop {
						display: none;
					}

					&__mobile {
						display: block;
					}
				}
			}

			.more {
				&.iconWrapper:hover .desktopMenuPosition {
					background-color: transparent;
				}

				&::after {
					display: none;
				}

				&__text {
					&::after {
						content: '\f0c9';
						font-family: FontAwesome;
						font-size: 20px;
						color: var(--color--primary--foreground);
						background-color: var(--color--interactive--background);
						border-radius: 50%;
						padding: 11px 12px;
					}

					span {
						display: none;
					}
				}

				.desktopMenuPosition {
					position: relative;
					z-index: 151;
				}

				.mobileMenuWrapper {
					display: block;
					position: fixed;
					top: 0;
					height: 100%;
					right: -100%;
					bottom: 0;
					width: 100%;
					@include minLaptop {
						max-width: 375px;
					}
					z-index: 150;

					&.expanding {
						transition: right 0.2s ease-out;
					}

					.mobileMenu {
						&__wrapper {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							overflow: hidden;
							width: 100%;
							background-color: var(--color--primary--background);
						}

						&__footer {
							text-transform: none;
							background-color: transparent;

							.image {
								align-self: flex-start;
							}
						}

						&__item {
							padding: 0 16px;

							a {
								text-transform: none;
								padding: 0;
								font-size: 16px;

								> span {
									padding: 21px 0;
								}
							}
						}
					}

					&.expanded {
						right: 0;

						.mobileMenu {
							&__item {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}
