// desktopMenu

.containerHeader {
	.additionalImages {
		position: relative;
		height: 100%;
		margin-left: 10px;
		@include M {
			margin-left: 0;
			padding-left: 5px;
		}
		&::after {
			content: '';
			height: 34%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			width: 1px;
			border-left: 1px solid rgba(255, 255, 255, 0.1);
			@include M {
				height: 51%;
			}
		}
		img {
			height: 45px;
			width: auto;
			margin-left: 20px;
			transition: all 0.2s ease-in;
			@include M {
				height: 30px;
				margin-left: 10px;
			}
			&:hover {
				transform: scale(1.05);
				cursor: pointer;
			}
		}
	}
	.desktopMenu {
		height: inherit;
		padding-right: 5px;
		@media all and (max-width: 1390px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		li {
			height: inherit;
			position: relative;
			flex-shrink: 0;
		}
		li > a,
		.accessPopup > a {
			padding: 9px 16px;
			border-radius: 35px;
			display: block;
			color: var(--color--primary--foreground);
			position: relative;
			letter-spacing: 1.5px;
			&:hover {
				background-color: var(--color--primary--foreground-30);
			}
			&.active:not(.external) {
				background-color: var(--color--interactive--background);
			}
			&::before {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border: {
					bottom: 9px solid transparent;
					left: 9px solid transparent;
					right: 9px solid transparent;
				}
				bottom: -7.4px;
				left: -6px;
				transform: rotate(-45deg);
				transition: border-bottom 0.25s ease-in;
			}
			&.logo {
				position: relative;
				top: -5px;
				width: 110px;
				height: 100px;
				padding: 0;
				border-bottom-left-radius: 50%;
				border-bottom-right-radius: 50%;
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
				background: {
					color: #fff;
					position: 16px 2px;
					size: 72%;
				}
				@media all and (max-width: 400px) {
					width: 105px;
					height: 105px;
					margin-top: 20px;
				}
				@include hM {
					height: 92px;
					width: 95px;
					background-position: 13px 9px;
				}
			}
			img {
				height: 16px;
				width: auto;
			}
			&.hasIcon {
				display: flex;
				text-align: center;
				vertical-align: middle;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				img {
					width: 30px;
					height: auto;
					@include ms {
						height: 100%;
					}
					@-moz-document url-prefix() {
						height: 100%;
					} // sass-lint:disable-line no-vendor-prefixes
					position: relative;
					+ span {
						visibility: hidden;
						position: absolute;
						padding: 0 9px;
						border-radius: 5px;
						background-color: var(--color--primary--background);
						top: 40px;
						left: 50%;
						color: transparent;
						transform: translate(-50%, 0);
						font-size: 12px;
						transition: padding 0.05s ease-in;
						text-transform: none;
					}
				}
				&:hover img + span {
					visibility: visible;
					padding: 7px 9px;
					color: var(--color--primary--foreground);
				}
			}
		}
	}
	.more {
		.mobileMenuWrapper {
			height: 100%;
		}
	}
	.mobileMenu {
		&__wrapper {
			background-color: var(--color--primary--background);
			box-shadow: 0 2px 6px 6px rgba(68, 68, 68, 0.05);
			position: absolute;
			top: 0;
			right: 0;
			overflow: hidden;
			width: 375px;
			cursor: default;
			height: 100%;
			display: flex;
			flex-direction: column;
			&__header {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
					var(--color--primary--background);
				min-height: $headerHeight--mobile;

				@include minLaptop {
					min-height: $headerHeight--laptop;
				}
				@include minDesktop {
					min-height: $headerHeight--desktop;
				}
			}
		}
		&__footer {
			background-color: var(--color--primary--background);
			padding: 32px 0;
			color: var(--color--primary--foreground);
			.image {
				width: 100%;
				height: 36px;
				filter: brightness(0) invert(1);
				margin-bottom: 10px;
			}
			p {
				line-height: 1.1;
			}
			a {
				color: var(--color--primary--foreground);
				cursor: pointer;
				line-height: 1.2;
			}
		}
		&__list {
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
				var(--color--primary--background);
			border-radius: 0 0 10px 10px;
			box-shadow: 0 2px 6px -6px rgba(68, 68, 68, 0.05);
		}
		&__item {
			padding: 0 32px;
			&:hover {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
					var(--color--primary--background);
			}
			> a,
			.accessPopup > a {
				padding: 27px 0;
				width: 100%;
				color: var(--color--primary--foreground);
				font-size: 16px;
				cursor: pointer;
			}
			img {
				width: auto;
				height: 16px;
			}
		}
	}
}
