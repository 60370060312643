// wrapper

.app {
	background-color: var(--color--content--background);
	color: var(--color--content--foreground);
	.missionListTechnical {
		width: 133px;
		@include L {
			display: none;
		}
		+ div {
			width: 100%;
		}
	}
	.middle {
		height: 100%;
		.mainContainer {
			background-color: var(--color--content--background);
		}
	}
	main {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		.main-container {
			position: relative;
			background-color: var(--color--primary--background);
			touch-action: none;
		}
		.mainContentWrapper.mainContentContainer {
			background-color: color-mod(var(--color--content--background) shade(0.1));
		}
	}

	.globalBar {
		height: 50px;
		background-color: #8f1c00;
		font-size: 25px;
		padding: 12px;
		color: white;
	}

	.globalBar + main {
		top: 50px;
	}
}
/*main {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	margin: 0;
    > div, > div > div {
		overflow-x: hidden;
		display: table;
		width: 100%;
		height: 100%;
	}
	section.header {
		display: table-row;
		height: $headerHeight--desktop;
		@include XXXL { height: $headerHeight--laptop; }
		@include M { height: $headerHeight--mobile; }
		> div {
			display: table-cell;
			height: inherit;
			background-color: var(--color--primary--background);
			position: relative;
			z-index: 400;
		}
	}
	section.middle {
		display: table-row;
		> div { display: table-cell; }
		.contentWrapper {
			display: table;
			width: 100%;
			height: 100%;
			section.missionListWrapper {
				width: $missionListWidth;
				display: table-cell;
				vertical-align: top;
				position: relative;
				z-index: 300;
				@include L { display: none; }
				+ section.mainContent { display: table-cell; }
			}
			section.mainContent {
				display: table-row;
				vertical-align: top;
				height: 100%;
				padding: 0;
				.mainContainer .customBackground {
					position: fixed;
					top: 68px;
					left: 0;
					bottom: 74px;
					right: 0;
					width: 100vw;
					@include hM {
						top: 58px;
						bottom: 55px;
					}
				}
				@include L { width: 100%; }
				@include S { padding: 0; }
			}
		}
	}
	section.footer {
		display: table-row;
		height: $footerHeight;
		@include XXXL { height: 67px; }
		@include hM { height: 55px; }
		> div {
			display: table-cell;
			height: inherit;
			position: relative;
			z-index: 900;
		}
	}
	&.verticalFullStretch {
		> div > div > section.header > div {
			padding-left: $missionListWidth;
			color: red;
			@include L { padding: 0; }
		}
		> div > div > section.middle .contentWrapper section.missionListWrapper + section.mainContent {
			padding-left: $missionListWidth;
			width: 100%;
			@include L {
				padding-left: 0;
				width: 100%;
			}
		}
		> div > div > section.middle > div > .contentWrapper section.missionListWrapper {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			@include L { display: none; }
		}
		> div > div > section.header ~ section.footer > div {
			padding-left: $missionListWidth;
			@include L { padding: 0; }
		}
	}
	//TODO: Implement extend (from verticalFullStretch class) properties.
	&.verticalTopStretch {
		> div > div > section.header > div { padding-left: $missionListWidth; }
		> div > div > section.middle .contentWrapper section.missionListWrapper + section.mainContent { padding-left: $missionListWidth; }
		> div > div > section.middle > div > .contentWrapper section.missionListWrapper {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: $footerHeight;
		}
		> div > div > section.header ~ section.footer > div { padding-left: 0; }
		section.footer {
			> div {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}
	}
	&.verticalBottomStretch {
		> div > div > section.middle > div > .contentWrapper section.missionListWrapper {
			display: block;
			position: absolute;
			left: 0;
			top: $headerHeight + 10px;
			bottom: 0;
			@include XXXL { top: $headerHeight; }
		}
		> div > div > section.middle .contentWrapper section.missionListWrapper + section.mainContent { padding-left: $missionListWidth; }
		> div > div > section.header ~ section.footer > div { padding-left: $missionListWidth; }
	}
	&.absoluteFooter {
		.mainSectionsWrapper {
			position: relative;
			section.middle { height: 100%; }
			section.footer {
				position: absolute;
				bottom: 20px;
				left: 30px;
				height: auto;
				@include M {
					position: static;
					height: 46px;
				}
			}
		}
	}
}*/
