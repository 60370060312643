@import '~animate-sass/animate';

@import 'common/reset';
@import 'common/normalize';
@import 'common/variables';
@import 'common/default';
@import 'common/mixins/mixins';
@import 'common/effects';
@import 'common/palette';

@import 'common/commons';
@import '_/main';

@import 'components/grids/grids';
@import 'components/buttons/buttons';
@import 'components/forms/forms';
@import 'components/forms/dropdown';
@import 'components/forms/filterField';

@import 'elements/wrapper/missionListWrapper';
@import 'elements/wrapper/wrapper';
@import 'elements/wrapper/footer/footer';
@import 'elements/wrapper/footer/pointsAnimation';
@import 'elements/wrapper/header/desktop';
@import 'elements/wrapper/header/mobile';
@import 'elements/wrapper/header/menu';

@import 'widgets/answer';
@import 'widgets/attributes';
@import 'widgets/arrow';
@import 'widgets/autocomplete';
@import 'widgets/autoScrollingText';
@import 'widgets/avatar';
@import 'widgets/availabilityCalendar';
@import 'widgets/close';
@import 'widgets/commentsSection';
@import 'widgets/cropper';
@import 'widgets/currencyContainer';
@import 'widgets/customInput';
@import 'widgets/customSelect';
@import 'widgets/dynamicFont';
@import 'widgets/emptyList';
@import 'widgets/editableTextField';
@import 'widgets/doubleSideTile';
@import 'widgets/fullSizeNotification';
@import 'widgets/graph';
@import 'widgets/matchingImageType';
@import 'widgets/imageViewer';
@import 'widgets/infoCloud';
@import 'widgets/languageSwitcher';
@import 'widgets/markedAvatar';
@import 'widgets/markdown';
@import 'widgets/progressBar';
@import 'widgets/repetitionTree';
@import 'widgets/returnArrow';
@import 'widgets/roundNumber';
@import 'widgets/roundVisualStatus';
@import 'widgets/scalableIframe';
@import 'widgets/standardList';
@import 'widgets/standardPreview';
@import 'widgets/statsTile';
@import 'widgets/submissionTile';
@import 'widgets/SVG';
@import 'widgets/viewTitleBox';
@import 'widgets/visualStatus';

@import 'views/prize/details';
@import 'views/achievement/details';
@import 'views/profilePreview/team';
@import 'views/profilePreview/rowList';
@import 'views/profilePreview/profile';
@import 'views/profilePreview/edit';
@import 'views/challenge/details';
@import 'views/challenge/KPI';
@import 'views/challenge/prizes';
@import 'views/challenge/ranking';
@import 'views/challenge/submissionDetails';
@import 'views/challenge/popup/submissionDetails';
@import 'views/challenge/popup/addSubmission';
@import 'views/challenge/popup/popup';
@import 'views/challenge/submissionList';
@import 'views/rules/rules';
@import 'views/auth';
@import 'views/faq';
@import 'views/userEdit';
@import 'views/teamEdit';
@import 'views/mission/graphPreview';
@import 'views/mission/details';
@import 'views/welcomePage';
@import 'views/news/preview';
@import 'views/news/list';
@import 'views/home';
@import 'views/map';
@import 'views/task/contentBlocks';
@import 'views/task/taskDetails';
@import 'views/task/mockup';
@import 'views/task/phraseQuestion';
@import 'views/task/taskDetailsMobile';
@import 'views/task/declarationTask';
@import 'views/task/visitLinkTask';
@import 'views/task/mobileTaskStatus';
@import 'views/teamAssignment';

@import 'widgets/popup';

@import 'views/mission/collectionMission';
@import 'views/employeePortal/employeePortal';
@import 'views/battles/_list';
@import 'views/battles/_details';

// Importing file directly from node_modules in js file creates some problems with scss processing,
//  e.g. color-mod() not working.
@import 'external/react-phone-number-input/style.css';
